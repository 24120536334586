/* Break points
-------------------------------------*/
$screen-xs: 768px;
$screen-sm: 1025px;
$screen-md: 1440px;

$gutter: 15px;

$grid-columns: 12;

// min screen size, container width
$breakpoints:
    sm $screen-xs 40rem,
    md $screen-sm 52rem,
    lg $screen-md 71rem;


@import '_partials/flex.scss';

.container-fluid {
    margin-right: auto;
    margin-left: auto;
}

.mbp-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.row {
    @include flexbox();
    @include flex(0, 1, auto);
    @include flex-direction(row);
    @include flex-wrap(wrap);
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;  
    box-sizing: border-box;
}

.row.reverse {
    @include flex-direction(row-reverse);
}

.col.reverse {
    @include flex-direction(column-reverse);
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
}

@mixin flexboxgrid-sass-col-common {

    // split @include flex(0, 0, auto) into individual props
    @include flex-grow(0);
    @include flex-shrink(0);

    // we leave @include flex-basis(auto) out of common because
    // in some spots we need it and some we dont
    // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126
    box-sizing: border-box;

    padding-right: $gutter;
    padding-left: $gutter;
}

$name: xs;
.col-#{$name} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(auto);
}
@for $i from 1 through $grid-columns {
    @if $i == 1 {
        [class*="col-#{$name}"] {
            @include flexboxgrid-sass-col-common;
            
        }
    }
    .col-#{$name}-#{$i} {
        @include flex-basis(100% / $grid-columns * $i);
        max-width: 100% / $grid-columns * $i;
    }
}
@for $i from 0 through $grid-columns {
    .col-#{$name}-offset-#{$i} {
        @if $i == 0 {
            margin-left: 0;
        } @else {
            margin-left: 100% / $grid-columns * $i;
        }
    }
}
.col-#{$name} {
    @include flex-grow(1);
    @include flex-basis(0);
    max-width: 100%;
}
.start-#{$name} {
    @include justify-content(flex-start);
}

.center-#{$name} {
    @include justify-content(center);
}

.end-#{$name} {
    @include justify-content(flex-end);
}

.top-#{$name} {
    @include align-items(flex-start);
}

.middle-#{$name} {
    @include align-items(center);
}

.bottom-#{$name} {
    @include align-items(flex-end);
}

.around-#{$name} {
    @include justify-content(space-around);
}

.between-#{$name} {
    @include justify-content(space-between);
}

.first-#{$name} {
    @include order(-1);
}

.last-#{$name} {
    @include order(1);
}


@each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);
    $container: nth($breakpoint, 3);
    @media only screen and (min-width: $size) {
        .container {
            width: $container;
        }

        .col-#{$name} {
            @include flexboxgrid-sass-col-common;
            @include flex-basis(auto);
        }
        @for $i from 1 through $grid-columns {
            @if $i == 1 {
                [class*="col-#{$name}"] {
                    @include flexboxgrid-sass-col-common;
                }
            }
            .col-#{$name}-#{$i} {
                @include flex-basis(100% / $grid-columns * $i);
                max-width: 100% / $grid-columns * $i;
            }
        }
        @for $i from 0 through $grid-columns {
            .col-#{$name}-offset-#{$i} {
                @if $i == 0 {
                    margin-left: 0;
                } @else {
                    margin-left: 100% / $grid-columns * $i;
                }
            }
        }
        .col-#{$name} {
            @include flex-grow(1);
            @include flex-basis(0);
            max-width: 100%;
        }
        .start-#{$name} {
            @include justify-content(flex-start);
        }

        .center-#{$name} {
            @include justify-content(center);
        }

        .end-#{$name} {
            @include justify-content(flex-end);
        }

        .top-#{$name} {
            @include align-items(flex-start);
        }

        .middle-#{$name} {
            @include align-items(center);
        }

        .bottom-#{$name} {
            @include align-items(flex-end);
        }

        .around-#{$name} {
            @include justify-content(space-around);
        }

        .between-#{$name} {
            @include justify-content(space-between);
        }

        .first-#{$name} {
            @include order(-1);
        }

        .last-#{$name} {
            @include order(1);
        }
    }
}

@media (max-width: ($screen-xs - 1px)) {
    .hidden-xs {
        display: none !important;
    }
    .invisible-xs {
        visibility: hidden;
    }
}
@media (min-width: $screen-xs) and (max-width: ($screen-sm - 1px)) {
    .hidden-sm {
        display: none !important;
    }
    .invisible-sm {
        visibility: hidden;
    }
}
@media (min-width: $screen-sm) and (max-width: ($screen-md - 1px)) {
    .hidden-md {
        display: none !important;
    }
    .invisible-md {
        visibility: hidden;
    }
}
@media (min-width: $screen-md) {
    .hidden-lg {
       display: none !important;
    }
    .invisible-lg {
        visibility: hidden;
    }
}

.visible-xs {
    @extend .hidden-sm;
    @extend .hidden-md;
    @extend .hidden-lg;
}
.visible-sm {
    @extend .hidden-xs;
    @extend .hidden-md;
    @extend .hidden-lg;
}
.visible-md {
    @extend .hidden-xs;
    @extend .hidden-sm;
    @extend .hidden-lg;
}
.visible-lg {
    @extend .hidden-xs;
    @extend .hidden-sm;
    @extend .hidden-md;
}
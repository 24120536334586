$sketchDesktopWidth: 1440;
$sketchMobileWidth: 320;

@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@mixin font-size($lg, $xs) {
    font-size: calc(#{strip-unit($xs)}px + (#{strip-unit($lg)} - #{strip-unit($xs)}) * ((100vw - #{$sketchMobileWidth}px) / (#{$sketchDesktopWidth} - #{$sketchMobileWidth})));
}

@mixin responsive-calc($el, $lg, $xs, $important:"") {
    $i: unquote("");
    @if $important != "" {
        $i: unquote("!important");
    }

    #{$el}: calc(#{strip-unit($xs)}px + (#{strip-unit($lg)} - #{strip-unit($xs)}) * ((100vw - #{$sketchMobileWidth}px) / (#{$sketchDesktopWidth} - #{$sketchMobileWidth}))) $i;
}

@function rng($min, $max) {
    @return $min + random($max - $min);
}
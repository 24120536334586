@import "_partials/colors";
@import 'mixins';
@import "fonts";
@import 'flexboxgrid';

body {
    margin: 0;
    background: #00B5D4 url("../images/background-main.jpg") no-repeat;
    background-size: contain;
    height: auto !important;

    @media(max-width: $screen-xs - 1) {
        background: #00B5D4 url("../images/background-main-mobile.jpg") no-repeat;
        background-size: contain;
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

header {
    position: absolute;
    width: 100%;
    max-width: 100vw;
    //overflow: hidden;

    > div {
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
        overflow: hidden;

        @media (max-width: $screen-xs - 1) {
            flex-direction: column;
            align-items: center;
        }

        .campaign-logo {
            position: relative;
            //@include responsive-calc(top, -150, -100);
            transform: rotate(-18deg);

            img {
                @include responsive-calc(max-width, 500, 320);
                @media(max-width: $screen-xs - 1) {
                    max-width: 550px;
                    margin-top: -90px;
                }

                @media(min-width: 412px) and (max-width: $screen-xs - 1) {
                    max-width: 700px;
                    margin-top: -130px;
                }
            }

            @media(min-width: $screen-xs) {
                left: -155px;
                top: -180px;
            }
        }

        .logo {
            padding-top: 30px;

            @media(min-width: $screen-xs) {
                padding-right: 30px;
            }

            @media(max-width: $screen-xs - 1) {
                order: -1;
                z-index: 1;
            }

            img {
                max-width: 200px;
            }
        }

        @media(max-width: $screen-xs - 1) {
            .campaign-logo, .logo {
                img {
                    opacity: 1;
                    transition: opacity 0.2s ease-in-out;
                    body.started & {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

}

footer {
    background-color: $pink-light;
    color: white;
    @include responsive-calc(padding-top, 70, 30);
    @include responsive-calc(padding-bottom, 70, 30);

    .text {
        text-align: center;
        font-family: sofia-pro-soft, sans-serif;
        @include font-size(17, 13);
    }

    .logo img {
        max-width: 300px;

        @media(max-width: $screen-xs - 1) {
            max-width: 200px;
        }
    }

    .logo-secondary img {
        max-width: 200px;
    }

    img {
        margin: auto;
        display: block;
    }

    @media(max-width: $screen-xs - 1) {
        > div {
            margin-bottom: 15px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

}

section {
    width: 100%;
    //height: 100vh;
    position: relative;

    &.hero {
        height: 100vh;

        img.image-overlay {
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        .heading {
            text-align: center;
            color: $white;
            transition: opacity 0.2s ease-in-out;

            body.started & {
                opacity: 0;
                visibility: hidden;
                height: 0;
            }

            h1 {
                margin-bottom: 10px;
            }

            @media(max-width: $screen-xs - 1) {
                text-transform: uppercase;
            }
        }

        .campaign-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .campaign-container {
                visibility: hidden;
                opacity: 0;
                position: relative;
                padding-top: 25px;
                height: 0;
                width: 100%;

                transition: opacity 0.5s ease-in-out;

                body.started & {
                    //padding-bottom: 56.25%; /* 16:9 */
                    visibility: visible;
                    opacity: 1;
                    z-index: 2;
                    height: auto;
                }

                iframe {
                    width: 100%;
                    height: 36.3vw;

                    @media(max-width: $screen-xs - 1) {
                        height: 49.7vw;
                    }
                }

            }

            .btn-container {
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                text-align: center;
                margin-top: 15px;

                .btn:first-child {
                    margin-right: 15px;

                    @media(max-width: $screen-xs - 1) {
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }

                body.started & {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .push-bottom {
                display: flex;
                flex-direction: column;
                align-items: center;

                position: absolute;
                bottom: 60px;

                @media(min-width: $screen-sm) and (max-width: 1650px) {
                    position: relative;
                    bottom: auto;
                    margin-top: 15px;
                }


                @media(max-width: $screen-sm - 1) {
                    bottom: 30px;
                }
            }
        }

        .swish-puff {
            .circle {
                visibility: hidden;
                opacity: 0;
                padding: 30px;
                text-align: center;
                color: #fff;
                width: 250px;
                flex-direction: column;
                justify-content: center;
                background: url('../images/swish-heart.png') no-repeat;
                background-size: contain;
                margin-bottom: -50px;
                display: flex;
                transition: opacity 0.5s ease-in-out, transform 0.2s ease-in-out;
                height: 0;
                transform: scale(0.2);

                body.started & {
                    height: 250px;
                    visibility: visible;
                    opacity: 1;
                    transform: scale(1);
                }

                &.right {
                    background: url('../images/swish-heart-big.png') no-repeat;
                    background-size: contain;
                    margin-bottom: 100px;

                    p {
                        margin: -30px 0 0;
                    }
                }

                p, h3 {
                    margin: -15px 0 0;
                }

                @media(max-width: 1500px) {
                    padding: 15px;

                    p, h3 {
                        margin: -45px 0 0 !important;
                    }

                    body.started & {
                        height: 210px;
                    }
                }

                @media(max-width: 1100px) {
                    body.started & {
                        height: 150px;
                    }
                }
            }
        }

    }

    &.content {

        @media(min-width: $screen-xs) {
            margin-top: 200px;
        }

        //flex-direction: column !important;

        .js-arrow {
            transform: rotate(-90deg);
            margin: auto;

            &:hover {
                transform: rotate(270deg);
            }
        }

        .text-box {
            margin-top: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 1;
            position: relative;

            .wrapper {
                background-color: $white;
                color: $pink;
                position: relative;
                @include responsive-calc(padding, 90, 30);
                border-radius: 90px; // IE fallback
                @include responsive-calc(border-radius, 90, 30);

                .super-colored > span {
                    color: $green;
                    &:nth-child(2) {
                        color: $yellow;
                    }
                    &:nth-child(3) {
                        color: $blue;
                    }
                }

                &:before {
                    content: "";
                    background: url("../images/top-img.png") no-repeat center bottom;
                    background-size: contain;
                    width: 80%;
                    height: 200px;
                    position: absolute;
                    top: 0;
                    left: 10%;
                    margin-top: -200px;
                }

                .paragraph p {
                    @include font-size(22, 15);
                    font-family: sofia-pro-soft, sans-serif;
                }
            }
        }

        .puffs {
            width: 100%;
            margin-top: -90px;
            padding-top: 180px;
            overflow: hidden;

            .headline {
                color: $white;
                margin-bottom: 60px;
            }
        }
    }
}

.btn {
    background-color: $pink;
    color: white;
    padding: 10px 60px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    border-radius: 60px;
    line-height: 1;
    transition: background-color 0.2s ease-in-out;

    @include font-size(60, 30);

    &:hover {
        background-color: $pink-light;
    }

    &.margin {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &.small {
        padding: 5px 60px;
    }

    &.bg-green {
        background-color: $green;
        &:hover {
            background-color: $green-light;
        }
    }
}

.js-arrow {
    z-index: 10;
    background: url("../images/chevron.svg") no-repeat center;
    background-size: contain;
    transform: rotate(90deg);
    height: 35px;
    width: 35px;
    cursor: pointer;
    transition: transform 0.8s ease-in-out;

    &.pulsate {
        animation: pulsate 3s ease-in-out infinite;
        animation-play-state: running;
        position: relative;

        &:hover {
            animation-play-state: paused;
        }
    }

    &:hover {
        transform: rotate(450deg);
    }

    @keyframes pulsate {
        0% {
            top: 0;
        }
        50% {
            top: 15px;
        }
        100% {
            top: 0px;
        }
    }
}

.center-text {
    text-align: center;
}

.green {
    color: $green;

    &-light {
        color: $green-light;
    }
}

.blue {
    color: $blue;
}

.yellow {
    color: $yellow;
}

.pink {
    color: $pink;
    &-light {
        color: $pink-light;
    }
}

.heart-container {
    position: relative;
    padding: 30px;

    .swish-heart {
        position: relative;
    }
}

.swish-heart {
    display: flex;
    padding: 30px;
    text-align: center;
    color: $white;
    @include responsive-calc(width, 350, 250);
    @include responsive-calc(height, 350, 250);
    flex-direction: column;
    justify-content: center;

    background: url('../images/swish-heart-big.png') no-repeat;
    background-size: contain;

    p {
        @include font-size(40, 30);
        margin: -15px 0 0;
    }

    &.block-center {
        margin: auto;
    }
}

.swish-puffs-mobile {
    display: flex;
    margin-top: 30px;

    //> .swish-heart {
    //    width: 50%;
    //    height: auto;
    //    padding: 75px 8px;
    //    background-position: center;
    //
    //    p {
    //        margin: 0;
    //    }
    //
    //    h3 {
    //        font-size: 30px;
    //    }
    //
    //    &.left {
    //        background: url('../images/swish-heart.png') no-repeat center;
    //        background-size: contain;
    //    }
    //}
}

.object {
    position: absolute;
    pointer-events: none;
    z-index: 15;
    display: none;
}

/*
Object stylings.. not the prettiest
 */

@media(min-width: $screen-sm) {

    #obj1 {
        display: block;
        right: 10%;
        top: -100px;
        width: 160px;
    }

    #obj2 {
        display: block;
        left: 10%;
        top: -100px;
        width: 150px;
    }

    #obj3 {
        display: block;
        width: 300px;
        left: -13%;
        top: 110px;

        @media(max-width: $screen-md) {
            display: none;
        }
    }

    #obj4 {
        display: block;
        left: -60px;
        bottom: -100px;
        width: 160px;
    }

    #obj5 {
        display: block;
        top: -110px;
        width: 220px;
        margin-left: -190px;
        z-index: -1;
        left: 50%;
    }

    #obj6 {
        display: block;
        width: 130px;
        top: 40%;
    }

    #obj7 {
        display: block;
        width: 130px;
        right: 0px;
        top: -80px;
    }

    #obj9 {
        display: block;
        top: -130px;
        height: 240px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    #obj10 {
        display: block;
        right: 0;
        top: -50px;
        width: 120px;

        @media(max-width: $screen-md) {
            right: -50px;
        }
    }

    #obj11 {
        display: block;
        left: -194.5px;
        width: 289px;
        top: 50%;
        pointer-events: auto;

        @media(max-width: $screen-md) {
            left: -220px;
        }
    }

    #obj12 {
        display: block;
        width: 130px;
        left: 0px;
        top: -80px;
    }
}

#obj8 {
    display: block;
    width: 200px;
    top: -40px;
    left: 55%;
    z-index: -1;
}

.js-getStarted {
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.bring-to-top {
    z-index: 2;
}

@import "_partials/fiftyfifty";
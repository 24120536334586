@font-face {
  font-family: 'Cherry Funk';
  src: url("../fonts/cherry-funk-webfont.woff2") format("woff2"), url("../fonts/cherry-funk-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: sofia-pro-soft, sans-serif;
  font-style: normal;
  font-weight: 700; }

h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0; }

h1 {
  font-size: calc(40px + (130 - 40) * ((100vw - 320px) / (1440 - 320)));
  line-height: 0.8;
  margin-bottom: 0; }

h2 {
  font-size: calc(70px + (140 - 70) * ((100vw - 320px) / (1440 - 320)));
  margin-bottom: 20px;
  line-height: 0.8; }

h3 {
  font-size: calc(50px + (70 - 50) * ((100vw - 320px) / (1440 - 320)));
  margin-bottom: 20px; }

p {
  margin: 0 0 15px; }

body {
  font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1440 - 320)));
  font-family: "Cherry Funk"; }

a {
  color: currentColor;
  text-decoration: none !important; }

/* Break points
-------------------------------------*/
.container-fluid {
  margin-right: auto;
  margin-left: auto; }

.mbp-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  box-sizing: border-box; }

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse; }

.col-xs {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

[class*="col-xs"] {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-1 {
  -webkit-flex-basis: 8.33333%;
  -moz-flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  -webkit-flex-basis: 16.66667%;
  -moz-flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  -webkit-flex-basis: 33.33333%;
  -moz-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  -webkit-flex-basis: 41.66667%;
  -moz-flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  -webkit-flex-basis: 58.33333%;
  -moz-flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  -webkit-flex-basis: 66.66667%;
  -moz-flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  -webkit-flex-basis: 83.33333%;
  -moz-flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  -webkit-flex-basis: 91.66667%;
  -moz-flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-0 {
  margin-left: 0; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start; }

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center; }

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end; }

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -moz-order: -1;
  -ms-flex-order: -1;
  order: -1; }

.last-xs {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-flex-order: 1;
  order: 1; }

@media only screen and (min-width: 768px) {
  .container {
    width: 40rem; }
  .col-sm {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  [class*="col-sm"] {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px; }
  .col-sm-1 {
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    margin-left: 0; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start; }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end; }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -moz-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-sm {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -moz-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media only screen and (min-width: 1025px) {
  .container {
    width: 52rem; }
  .col-md {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  [class*="col-md"] {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px; }
  .col-md-1 {
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    margin-left: 0; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start; }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end; }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -moz-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-md {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -moz-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media only screen and (min-width: 1440px) {
  .container {
    width: 71rem; }
  .col-lg {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  [class*="col-lg"] {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px; }
  .col-lg-1 {
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    margin-left: 0; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start; }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end; }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -moz-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .last-lg {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -moz-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  .hidden-xs, .visible-sm, .visible-md, .visible-lg {
    display: none !important; }
  .invisible-xs {
    visibility: hidden; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .hidden-sm, .visible-xs, .visible-md, .visible-lg {
    display: none !important; }
  .invisible-sm {
    visibility: hidden; } }

@media (min-width: 1025px) and (max-width: 1439px) {
  .hidden-md, .visible-xs, .visible-sm, .visible-lg {
    display: none !important; }
  .invisible-md {
    visibility: hidden; } }

@media (min-width: 1440px) {
  .hidden-lg, .visible-xs, .visible-sm, .visible-md {
    display: none !important; }
  .invisible-lg {
    visibility: hidden; } }

body {
  margin: 0;
  background: #00B5D4 url("../images/background-main.jpg") no-repeat;
  background-size: contain;
  height: auto !important; }
  @media (max-width: 767px) {
    body {
      background: #00B5D4 url("../images/background-main-mobile.jpg") no-repeat;
      background-size: contain; } }

*, *:before, *:after {
  box-sizing: border-box; }

header {
  position: absolute;
  width: 100%;
  max-width: 100vw; }
  header > div {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    overflow: hidden; }
    @media (max-width: 767px) {
      header > div {
        flex-direction: column;
        align-items: center; } }
    header > div .campaign-logo {
      position: relative;
      transform: rotate(-18deg); }
      header > div .campaign-logo img {
        max-width: calc(320px + (500 - 320) * ((100vw - 320px) / (1440 - 320))) ; }
        @media (max-width: 767px) {
          header > div .campaign-logo img {
            max-width: 550px;
            margin-top: -90px; } }
        @media (min-width: 412px) and (max-width: 767px) {
          header > div .campaign-logo img {
            max-width: 700px;
            margin-top: -130px; } }
      @media (min-width: 768px) {
        header > div .campaign-logo {
          left: -155px;
          top: -180px; } }
    header > div .logo {
      padding-top: 30px; }
      @media (min-width: 768px) {
        header > div .logo {
          padding-right: 30px; } }
      @media (max-width: 767px) {
        header > div .logo {
          order: -1;
          z-index: 1; } }
      header > div .logo img {
        max-width: 200px; }
    @media (max-width: 767px) {
      header > div .campaign-logo img, header > div .logo img {
        opacity: 1;
        transition: opacity 0.2s ease-in-out; }
        body.started header > div .campaign-logo img, body.started header > div .logo img {
          opacity: 0.6; } }

footer {
  background-color: #FFB4BE;
  color: white;
  padding-top: calc(30px + (70 - 30) * ((100vw - 320px) / (1440 - 320))) ;
  padding-bottom: calc(30px + (70 - 30) * ((100vw - 320px) / (1440 - 320))) ; }
  footer .text {
    text-align: center;
    font-family: sofia-pro-soft, sans-serif;
    font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1440 - 320))); }
  footer .logo img {
    max-width: 300px; }
    @media (max-width: 767px) {
      footer .logo img {
        max-width: 200px; } }
  footer .logo-secondary img {
    max-width: 200px; }
  footer img {
    margin: auto;
    display: block; }
  @media (max-width: 767px) {
    footer > div {
      margin-bottom: 15px; }
      footer > div:last-of-type {
        margin-bottom: 0; } }

section {
  width: 100%;
  position: relative; }
  section.hero {
    height: 100vh; }
    section.hero img.image-overlay {
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1; }
    section.hero .heading {
      text-align: center;
      color: #fff;
      transition: opacity 0.2s ease-in-out; }
      body.started section.hero .heading {
        opacity: 0;
        visibility: hidden;
        height: 0; }
      section.hero .heading h1 {
        margin-bottom: 10px; }
      @media (max-width: 767px) {
        section.hero .heading {
          text-transform: uppercase; } }
    section.hero .campaign-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      section.hero .campaign-wrapper .campaign-container {
        visibility: hidden;
        opacity: 0;
        position: relative;
        padding-top: 25px;
        height: 0;
        width: 100%;
        transition: opacity 0.5s ease-in-out; }
        body.started section.hero .campaign-wrapper .campaign-container {
          visibility: visible;
          opacity: 1;
          z-index: 2;
          height: auto; }
        section.hero .campaign-wrapper .campaign-container iframe {
          width: 100%;
          height: 36.3vw; }
          @media (max-width: 767px) {
            section.hero .campaign-wrapper .campaign-container iframe {
              height: 49.7vw; } }
      section.hero .campaign-wrapper .btn-container {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        text-align: center;
        margin-top: 15px; }
        section.hero .campaign-wrapper .btn-container .btn:first-child {
          margin-right: 15px; }
          @media (max-width: 767px) {
            section.hero .campaign-wrapper .btn-container .btn:first-child {
              margin-right: 0;
              margin-bottom: 5px; } }
        body.started section.hero .campaign-wrapper .btn-container {
          visibility: visible;
          opacity: 1; }
      section.hero .campaign-wrapper .push-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 60px; }
        @media (min-width: 1025px) and (max-width: 1650px) {
          section.hero .campaign-wrapper .push-bottom {
            position: relative;
            bottom: auto;
            margin-top: 15px; } }
        @media (max-width: 1024px) {
          section.hero .campaign-wrapper .push-bottom {
            bottom: 30px; } }
    section.hero .swish-puff .circle {
      visibility: hidden;
      opacity: 0;
      padding: 30px;
      text-align: center;
      color: #fff;
      width: 250px;
      flex-direction: column;
      justify-content: center;
      background: url("../images/swish-heart.png") no-repeat;
      background-size: contain;
      margin-bottom: -50px;
      display: flex;
      transition: opacity 0.5s ease-in-out, transform 0.2s ease-in-out;
      height: 0;
      transform: scale(0.2); }
      body.started section.hero .swish-puff .circle {
        height: 250px;
        visibility: visible;
        opacity: 1;
        transform: scale(1); }
      section.hero .swish-puff .circle.right {
        background: url("../images/swish-heart-big.png") no-repeat;
        background-size: contain;
        margin-bottom: 100px; }
        section.hero .swish-puff .circle.right p {
          margin: -30px 0 0; }
      section.hero .swish-puff .circle p, section.hero .swish-puff .circle h3 {
        margin: -15px 0 0; }
      @media (max-width: 1500px) {
        section.hero .swish-puff .circle {
          padding: 15px; }
          section.hero .swish-puff .circle p, section.hero .swish-puff .circle h3 {
            margin: -45px 0 0 !important; }
          body.started section.hero .swish-puff .circle {
            height: 210px; } }
      @media (max-width: 1100px) {
        body.started section.hero .swish-puff .circle {
          height: 150px; } }
  @media (min-width: 768px) {
    section.content {
      margin-top: 200px; } }
  section.content .js-arrow {
    transform: rotate(-90deg);
    margin: auto; }
    section.content .js-arrow:hover {
      transform: rotate(270deg); }
  section.content .text-box {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    position: relative; }
    section.content .text-box .wrapper {
      background-color: #fff;
      color: #F13C5F;
      position: relative;
      padding: calc(30px + (90 - 30) * ((100vw - 320px) / (1440 - 320))) ;
      border-radius: 90px;
      border-radius: calc(30px + (90 - 30) * ((100vw - 320px) / (1440 - 320))) ; }
      section.content .text-box .wrapper .super-colored > span {
        color: #00DD24; }
        section.content .text-box .wrapper .super-colored > span:nth-child(2) {
          color: #F4E800; }
        section.content .text-box .wrapper .super-colored > span:nth-child(3) {
          color: #00B4D2; }
      section.content .text-box .wrapper:before {
        content: "";
        background: url("../images/top-img.png") no-repeat center bottom;
        background-size: contain;
        width: 80%;
        height: 200px;
        position: absolute;
        top: 0;
        left: 10%;
        margin-top: -200px; }
      section.content .text-box .wrapper .paragraph p {
        font-size: calc(15px + (22 - 15) * ((100vw - 320px) / (1440 - 320)));
        font-family: sofia-pro-soft, sans-serif; }
  section.content .puffs {
    width: 100%;
    margin-top: -90px;
    padding-top: 180px;
    overflow: hidden; }
    section.content .puffs .headline {
      color: #fff;
      margin-bottom: 60px; }

.btn {
  background-color: #F13C5F;
  color: white;
  padding: 10px 60px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border-radius: 60px;
  line-height: 1;
  transition: background-color 0.2s ease-in-out;
  font-size: calc(30px + (60 - 30) * ((100vw - 320px) / (1440 - 320))); }
  .btn:hover {
    background-color: #FFB4BE; }
  .btn.margin {
    margin-top: 30px;
    margin-bottom: 30px; }
  .btn.small {
    padding: 5px 60px; }
  .btn.bg-green {
    background-color: #00DD24; }
    .btn.bg-green:hover {
      background-color: #67FF84; }

.js-arrow {
  z-index: 10;
  background: url("../images/chevron.svg") no-repeat center;
  background-size: contain;
  transform: rotate(90deg);
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: transform 0.8s ease-in-out; }
  .js-arrow.pulsate {
    animation: pulsate 3s ease-in-out infinite;
    animation-play-state: running;
    position: relative; }
    .js-arrow.pulsate:hover {
      animation-play-state: paused; }
  .js-arrow:hover {
    transform: rotate(450deg); }

@keyframes pulsate {
  0% {
    top: 0; }
  50% {
    top: 15px; }
  100% {
    top: 0px; } }

.center-text {
  text-align: center; }

.green {
  color: #00DD24; }
  .green-light {
    color: #67FF84; }

.blue {
  color: #00B4D2; }

.yellow {
  color: #F4E800; }

.pink {
  color: #F13C5F; }
  .pink-light {
    color: #FFB4BE; }

.heart-container {
  position: relative;
  padding: 30px; }
  .heart-container .swish-heart {
    position: relative; }

.swish-heart {
  display: flex;
  padding: 30px;
  text-align: center;
  color: #fff;
  width: calc(250px + (350 - 250) * ((100vw - 320px) / (1440 - 320))) ;
  height: calc(250px + (350 - 250) * ((100vw - 320px) / (1440 - 320))) ;
  flex-direction: column;
  justify-content: center;
  background: url("../images/swish-heart-big.png") no-repeat;
  background-size: contain; }
  .swish-heart p {
    font-size: calc(30px + (40 - 30) * ((100vw - 320px) / (1440 - 320)));
    margin: -15px 0 0; }
  .swish-heart.block-center {
    margin: auto; }

.swish-puffs-mobile {
  display: flex;
  margin-top: 30px; }

.object {
  position: absolute;
  pointer-events: none;
  z-index: 15;
  display: none; }

/*
Object stylings.. not the prettiest
 */
@media (min-width: 1025px) {
  #obj1 {
    display: block;
    right: 10%;
    top: -100px;
    width: 160px; }
  #obj2 {
    display: block;
    left: 10%;
    top: -100px;
    width: 150px; }
  #obj3 {
    display: block;
    width: 300px;
    left: -13%;
    top: 110px; } }
  @media (min-width: 1025px) and (max-width: 1440px) {
    #obj3 {
      display: none; } }

@media (min-width: 1025px) {
  #obj4 {
    display: block;
    left: -60px;
    bottom: -100px;
    width: 160px; }
  #obj5 {
    display: block;
    top: -110px;
    width: 220px;
    margin-left: -190px;
    z-index: -1;
    left: 50%; }
  #obj6 {
    display: block;
    width: 130px;
    top: 40%; }
  #obj7 {
    display: block;
    width: 130px;
    right: 0px;
    top: -80px; }
  #obj9 {
    display: block;
    top: -130px;
    height: 240px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
  #obj10 {
    display: block;
    right: 0;
    top: -50px;
    width: 120px; } }
  @media (min-width: 1025px) and (max-width: 1440px) {
    #obj10 {
      right: -50px; } }

@media (min-width: 1025px) {
  #obj11 {
    display: block;
    left: -194.5px;
    width: 289px;
    top: 50%;
    pointer-events: auto; } }
  @media (min-width: 1025px) and (max-width: 1440px) {
    #obj11 {
      left: -220px; } }

@media (min-width: 1025px) {
  #obj12 {
    display: block;
    width: 130px;
    left: 0px;
    top: -80px; } }

#obj8 {
  display: block;
  width: 200px;
  top: -40px;
  left: 55%;
  z-index: -1; }

.js-getStarted {
  opacity: 0;
  transition: opacity .3s ease-in-out; }

.bring-to-top {
  z-index: 2; }

div.fiftyfifty {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #fff; }
  div.fiftyfifty > div {
    flex-basis: 50%;
    max-width: 50%; }
    @media (max-width: 1024px) {
      div.fiftyfifty > div {
        flex-basis: 100%;
        max-width: 100%; } }
  div.fiftyfifty > .video {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    div.fiftyfifty > .video .container {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: calc(260px + (500 - 260) * ((100vw - 320px) / (1440 - 320))) ;
      height: calc(260px + (500 - 260) * ((100vw - 320px) / (1440 - 320))) ;
      -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
      /*ios 7 border-radius-bug */
      -webkit-transform: rotate(0deg);
      /*mac os 10.6 safari 5 border-radius-bug */
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%; }
  div.fiftyfifty > .text {
    text-align: center;
    position: relative; }
    @media (max-width: 767px) {
      div.fiftyfifty > .text h3 {
        margin-top: 20px; } }
  @media (min-width: 1025px) {
    div.fiftyfifty.right > .text {
      order: -1; } }
  div.fiftyfifty:last-of-type {
    margin-bottom: calc(30px + (70 - 30) * ((100vw - 320px) / (1440 - 320))) ; }

@font-face {
    font-family: 'Cherry Funk';
    src: url('../fonts/cherry-funk-webfont.woff2') format('woff2'),
    url('../fonts/cherry-funk-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: sofia-pro-soft, sans-serif;
    font-style: normal;
    font-weight: 700;
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
    margin: 0;
}

h1 {
    @include font-size(130, 40);
    line-height: 0.8;
    margin-bottom: 0;
}

h2 {
    @include font-size(140, 70);
    margin-bottom: 20px;
    line-height: 0.8;
}

h3 {
    @include font-size(70, 50);
    margin-bottom: 20px;
}

p {
    margin: 0 0 15px;
}

body {
    @include font-size(30, 20);
    font-family: "Cherry Funk";
}

a {
    color: currentColor;
    text-decoration: none !important;
}
div.fiftyfifty {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: $white;

    > div {
        flex-basis: 50%;
        max-width: 50%;

        @media(max-width: $screen-sm - 1) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    > .video {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @include responsive-calc(width, 500, 260);
            @include responsive-calc(height, 500, 260);
            -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); /*ios 7 border-radius-bug */
            -webkit-transform: rotate(0.000001deg); /*mac os 10.6 safari 5 border-radius-bug */
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }
    }

    > .text {
        text-align: center;
        position: relative;

        @media(max-width: $screen-xs - 1) {
            h3 {
                margin-top: 20px;
            }
        }
    }

    @media(min-width: $screen-sm) {
        &.right {
            > .text {
                order: -1;
            }
        }
    }

    &:last-of-type {
        @include responsive-calc(margin-bottom, 70, 30);
    }
}